import * as React from "react";

export default function Home() {
    return (
        <div className=" mb-0">
            <div className="float-right">
                <a href="/fr">
                    <p className="text-xs mt-1 on-hover-40 text-white label-name"></p>
                </a>
            </div>
        </div>
    );
}
